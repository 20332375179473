import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IStyleLink } from '../../shared/cardholders-core/models/style-link';
import { AuthService } from '../../shared/cardholders-core/services/auth.service';
import { Ave8Service } from '../../shared/cardholders-core/services/ave8.service';
import { LoggerService } from '../../shared/cardholders-core/services/logger.service';
import { UiHelperService } from '../../shared/cardholders-core/services/ui-helper.service';
import { Server8Service } from '../server8/server8.service';
import { SsoGeneralService } from './services/sso-general.service';

@Component({
    selector: 'app-sso-general',
    templateUrl: './sso-general.component.html',
    styleUrls: ['./sso-general.component.scss']
})
export class SsoGeneralComponent implements OnInit {
    styleLinks: IStyleLink[] = [
        {
            href: '/styles/main-layout-styles.bundle.min.css',
            id: 'mainLayoutbundleStyles'
        }
    ];
    constructor(
        private logger: LoggerService,
        private router: Router,
        private route: ActivatedRoute,
        private ssoSrv: SsoGeneralService,
        private authSerivce: AuthService,
        private uiHelper: UiHelperService,
        private ave8Serv: Ave8Service,
        private serverService: Server8Service
    ) { }
    ngOnInit(): void {
        if (!this.uiHelper.isBrowser) {
            return;
        }
        if (this.route.snapshot.queryParamMap.has('eve')) {
            this.serverService.setCookie("eve", this.route.snapshot.queryParamMap.get('eve'));
            this.serverService.setCookie("isFromSSO8", true);
            let url = new URL(window.location.href);
            url.searchParams.delete("eve");
            window.location.href = url.toString();
            return;
        }
        if (!this.route.snapshot.queryParamMap.has('guid') && !this.route.snapshot.queryParamMap.has('Guid')) {
            this.router.navigate(['/errornew?msg=guidnotfound']);
            return;
        }
        const guid = this.route.snapshot.queryParamMap.get('guid') || this.route.snapshot.queryParamMap.get('Guid');
        this.ssoSrv.getUrl(guid).subscribe(x => {
            switch (x.returnCode) {
                case 0:
                case 11:
                    if (!x.result.url) {
                        this.router.navigate(['/errornew']);
                        return;
                    }
                    if (x.result.roles && x.result.roles.length) {
                        this.authSerivce.markUserHasAuthenticated(x.result.roles);
                    }
                    let toUrl;

                    try {
                        toUrl = new URL(x.result.url);
                    } catch (e) {
                        console.error(e);
                        this.router.navigate(['/errornew']);
                        return;
                    }

                    this.authSerivce.isMobileSso = x.result.isMobileSSO;

                    if (window.location.origin === toUrl.origin &&
                        ((!this.ave8Serv.checkCookie8() && !this.ave8Serv.isAve8()) || (this.ave8Serv.checkCookie8() && this.ave8Serv.isAve8()))) {
                        this.router.navigateByUrl(toUrl.pathname + toUrl.search);
                        return;
                    }

                    if (x.result.url.indexOf("/choose/pay") > -1)
                    {
                        let _token = sessionStorage.getItem('Token');

                        if (_token) {
                            localStorage.setItem('Token', _token);
                        }
                        else {
                            this.logger.error("MaxAccount local pay: Copy to local storage failed: No token found in session storage");
                        }
                    }

                    window.location.href = x.result.url;
                    break;

                default:
                    this.router.navigate(['/errornew']);
                    return;
            }
        });
    }
}
