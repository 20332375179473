<!-- <seo-head title="הלוואות כרטיסי אשראי ושירותי סליקה max" searchDescription="מקס מציעים לכם מבחר הלוואות בתנאים נוחים, כרטיסי אשראי שמזכים אתכם בהטבות ייחודיות, ופתרונות סליקה לעסק שלכם"></seo-head> -->
<!--<app-cookies-notification [element]="homepage"></app-cookies-notification>-->
<section class="homepage" [ngClass]="{'homepage-header': isHeaderTransparent}" #homepage>
    <app-header [homepageElement]="homepage" (headerSizeChanged)="onHeaderSizeChanged($event)"></app-header>
    <section class="content col-sm-12 homepage-inner" [ngStyle]="getStyleForSection()">
        <router-outlet></router-outlet>
        <app-asteriks [hidden]="removeLayout"></app-asteriks>
        <app-footer *ngIf="!removeLayout"></app-footer>
        <div class="pop-up-back"></div>
    </section>
</section>
<app-load-style [styleList]="styleLinks"></app-load-style>
