import { Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { isNullOrUndefined } from 'core-max-lib';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[maxTooltip]'
})
export class NewTooltipDirective implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Output() clickedToolTip: EventEmitter<void> = new EventEmitter<void>();

    @Input('maxTooltip') tooltipTitle: string;

    @Input('extraMarginForRightTooltipClass') extraMarginForRightTooltip:number;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
        if (!this.tooltipTitle) return;
        const icon = this.renderer.createElement('span');
        this.renderer.addClass(icon, 'max-tooltip-icon');

        const tooltip = this.renderer.createElement('span') as HTMLElement;
        this.renderer.addClass(tooltip, 'max-tooltip-message');
        tooltip.innerHTML = this.tooltipTitle;
        let extraClass: string;

        let timeOutId: any;

        fromEvent(icon, 'mouseover', { passive: true }).subscribe(() => {
            if (timeOutId) clearTimeout(timeOutId);
            if (!icon.childElementCount) this.renderer.appendChild(icon, tooltip);
            if (extraClass === undefined) {
                const extraClass = this.getBorderClass(tooltip.getBoundingClientRect());
                if (extraClass) this.renderer.addClass(tooltip, extraClass);
            }
        });

        fromEvent(icon, 'click', { passive: true }).subscribe((e: any) => {
            this.clickedToolTip.emit();
            // if (e.target.classList.contains('max-tooltip-message')) debugger;
            if (e.target.classList.contains('max-tooltip-message')) return;
            if (icon.childElementCount) {
                this.renderer.removeChild(icon, tooltip);
            } else {
                if (!icon.childElementCount) this.renderer.appendChild(icon, tooltip);
                if (extraClass === undefined) {
                    const extraClass = this.getBorderClass(tooltip.getBoundingClientRect());
                    if (extraClass) this.renderer.addClass(tooltip, extraClass);
                }
            }
        });

        fromEvent(icon, 'mouseout', { passive: true }).subscribe(() => {
            timeOutId = setTimeout(() => {
                if (icon.childElementCount) this.renderer.removeChild(icon, tooltip);
                timeOutId = null;
            }, 25);
        });

        // Mange mobile logic separateky
        fromEvent(icon, 'touchend').subscribe((e: any) => {
            this.clickedToolTip.emit();
            e.stopPropagation();
            e.preventDefault();

            let subscription: Subscription;
            if (icon.childElementCount) {
                this.renderer.removeChild(icon, tooltip);
                if (subscription) subscription.unsubscribe();
            } else {
                this.renderer.appendChild(icon, tooltip);

                if (extraClass === undefined) {
                    const extraClass = this.getBorderClass(tooltip.getBoundingClientRect());
                    if (extraClass) this.renderer.addClass(tooltip, extraClass);
                }

                subscription = fromEvent(document, 'touchend', { capture: true }).subscribe(event => {
                    this.renderer.removeChild(icon, tooltip);
                    subscription.unsubscribe();
                    event.stopPropagation();
                    event.preventDefault();
                });
            }
        });

        this.renderer.appendChild(this.el.nativeElement, icon);
    }

    private getBorderClass(rect: any): string {
        if (rect.x < 0) return 'left-tooltip';
        if(isNullOrUndefined(this.extraMarginForRightTooltip)){
            if (rect.x + rect.width > window.innerWidth) return 'right-tooltip';
        }else{
            if (rect.x + rect.width +this.extraMarginForRightTooltip> window.innerWidth) return 'right-tooltip';
        }

        return null;
    }
}
