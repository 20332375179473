import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, Inject } from '@angular/core';
import { MainLayoutService } from '../../services/main-layout.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { LoginService } from '../../../../modules/login/login.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { a11yFix } from '../../../../shared/utils/a11y.utils';
declare var $: any;

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
    @Input()
    minimal: boolean;
    @Input()
    isMobileLandscape: boolean;
    navbarCollapsed = true;
    isNavOpen = false;
    isUserBoxOpen: boolean;
    menu: any;
    businessRoot: string;
    isMobile: boolean;
    companyName: string;
    companyLogoImage: string;
    isUserAuthenticated: boolean;
    private subs: Subscription[] = [];
    locationSubscription: SubscriptionLike;
    @Input() isEve8: boolean;
    @Input() isEve10: boolean;


    constructor(
        private mainLayoutService: MainLayoutService,
        public authService: AuthService,
        public uiHelper: UiHelperService,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private appConfig: AppConfigsService,
        private loginSvc: LoginService,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private appConfigService: AppConfigsService,
        private location: Location
    ) {
        this.menu = {};
        this.businessRoot = this.appConfig.appConfigs.businessRoot;
        this.isMobile = this.uiHelper.IsMobile(768) || this.isMobileLandscape;
        this.isUserAuthenticated = this.authService.isUserAuthenticated();
        this.companyName = this.appConfig.appConfigs.companyName.toLowerCase();
        /*this.companyLogoImage = '/assets/images/homepage/max-logo.svg';*/
        this.companyLogoImage = '/assets/images/homepage/log-max-yelow-sign.svg'; /*logo for war time*/
    }

    ngOnInit() {

        if (this.isMobile) {
            //mobile burger button
            a11yFix()?.dialog("button.navbar-toggler", {
                selectors: {
                    dialog: ".navbar-nav",
                    trigger: "button.navbar-toggler",
                },
                type: "non_modal"
            });

            a11yFix()?.accordion("li.nav-item.dropdown", {
                selectors: {
                    headerSelector: "li.nav-item.dropdown, .sub-menu>li>a",
                    contentSelector: "ul.sub-menu, ul.sub-menu-links"
                },
            });
        } else {
            //desktop menu
            a11yFix()?.menu(".nav-item", {
                selectors: {
                    menu: ".navbar-nav",
                    submenus: ".dropdown-menu",
                    items: ".nav-item:not(.to-business-item), .to-business-item>a, .dropdown-item",
                    triggers: ".nav-item.dropdown",
                    horizontalMenu: ".navbar-nav",
                    openByMouseenter: ".nav-item.dropdown"
                }
            });

        }

        this.subs.push(
            this.mainLayoutService.isNavOpen.subscribe((isNavOpen) => {
                if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
                    this.insideMobileToggleNav(isNavOpen);
                } else {
                    this.insideDesktopToggleNav(isNavOpen);
                }
            })
        );
        this.subs.push(
            this.mainLayoutService.isUserBoxOpen.subscribe((isUserBoxOpen) => {
                this.isUserBoxOpen = isUserBoxOpen;
                if (isUserBoxOpen && !this.navbarCollapsed) {
                    this.toggleNav();
                }
            })
        );
        if (
            this.minimal ||
            this.uiHelper.IsMobile(768) ||
            this.uiHelper.IsMobileByScreen(768) ||
            this.isMobileLandscape
        ) {
            this.mainLayoutService.getMenuWithActions().subscribe((res) => {
                this.initMenu(res.Result);
            });
        } else {
            this.mainLayoutService.loadMenu().then((res: any) => {
                this.initMenu(res);
            });
        }

        if (this.uiHelper.isBrowser) {
            this.isNavOpen = false;
            document.querySelector('.homepage .pop-up-back').classList.remove('show-back-mega-menu');
        }

        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: any) => {
            if (this.uiHelper.isBrowser) {
                this.handleNavAfterRouting();
            }
        });

        if (this.uiHelper.isBrowser && this.isMobile) {
            if (this.router.url.includes('/loans/calc')) {
                $('.navbar-nav .dropdown').each((_, item) => {
                    if ($($(item).children('a')).hasClass('open-for-loan')) {
                        $(item).children('a')[0].click();
                    }
                });
            }

            setTimeout((_) => {
                $('.sub-menu').each((_, subItem) => {
                    if ($($(subItem).children('li').children('a')).hasClass('open-for-loan')) {
                        $(subItem).children('li').children('a')[0].click();
                    }
                });
            }, 1000);
        }

        setTimeout((_) => {
            $(".collapse.navbar-collapse").removeAttr("aria-hidden");
        }, 1000);
    }

    handleNavAfterRouting() {
        if (!this.isMobile) {
            if (!isNullOrUndefined(document.querySelector('.dropdown-menu.show'))) {
                document.querySelector('.dropdown-menu.show').classList.add('hide-nav');
                this.removeClassAfterTimeout();
            } else if (!isNullOrUndefined(document.querySelector('.dropdown.link-deco'))) {
                var dropdownElement = document.querySelector('.dropdown.link-deco');
                dropdownElement.querySelector('.dropdown-menu').classList.add('hide-nav');
                this.removeClassAfterTimeout();
            }
        } else {
            this.mainLayoutService.changeNavMode(false);
        }
    }

    removeClassAfterTimeout() {
        setTimeout((_) => {
            if (!isNullOrUndefined(document.querySelector('.dropdown-menu.hide-nav'))) {
                document.querySelector('.dropdown-menu.hide-nav').classList.remove('hide-nav');
            }
        }, 1000);
    }

    ngOnDestroy() {
        this.cd.detach();
        this.subs.forEach((sub) => sub.unsubscribe());
        this.navbarCollapsed = true;
        this.isNavOpen = false;
        if (this.uiHelper.isBrowser) {
            document.querySelector('.homepage .pop-up-back').classList.remove('show-back-mega-menu');
            if (
                (this.uiHelper.IsMobile(768) || this.isMobileLandscape) &&
                !isNullOrUndefined(this.locationSubscription)
            ) {
                this.locationSubscription.unsubscribe();
            }
        }
    }

    getSubCategory(categories: any) {
        for (const category of categories) {
            for (let i = 0; i < category.SubCategories.length; i++) {
                if (category.SubCategories[i].Name === '') {
                    category.SubCategories[i - 1].MenuItems.push(...category.SubCategories[i].MenuItems);
                    category.SubCategories.splice(i, 1);
                }
            }
        }
    }

    onMouseEnter(dropdown: any) {
        if (!this.uiHelper.IsMobile(768) && !this.isMobileLandscape) {
            dropdown.show();
            dropdown._elementRef.nativeElement.classList.toggle('link-deco');
            this.mainLayoutService.changeNavMode(true);
        }
    }

    onBackButtonClick() {
        //history.forward();
        this.mainLayoutService.changeNavMode(false);
        return false;
    }

    toggleNav() {
        if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
            this.mainLayoutService.changeNavMode(this.navbarCollapsed);
        } else {
            this.mainLayoutService.changeNavMode(!this.isNavOpen);
        }
    }

    onMouseLeave(dropdown: any) {
        if (!this.uiHelper.IsMobile(768) && !this.isMobileLandscape) {
            dropdown.hide();
            dropdown._elementRef.nativeElement.classList.toggle('link-deco');
            this.mainLayoutService.changeNavMode(false);
        }
    }

    onCategoryClick(dropdown, id) {
        if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
            if (!document.getElementById(id).classList.toggle('show')) {
                dropdown.hide();
            } else {
                dropdown.show();
            }
        }
    }

    getFixedNameIE(name: any) {
        return this.uiHelper.isBrowser ? (!this.uiHelper.detectIE() ? name : name.replace('"', "''")) : name;
    }

    getCategoryLink(category: any) {
        return !this.uiHelper.IsMobile() && !this.isMobileLandscape ? category.Link : undefined;
    }

    getCategoryLinkRoles(category: any) {
        return !this.uiHelper.IsMobile() && !this.isMobileLandscape ? category.LinkRoles : undefined;
    }
    getMenuItemLink(menuItem: any) {
        if (
            this.authService.isUserAuthenticated() &&
            !isNullOrUndefined(menuItem.LinkRegistered) &&
            menuItem.LinkRegistered !== ''
        ) {
            return menuItem.LinkRegistered;
        }
        return menuItem.Link;
    }

    getMenuItemLinkRegisteredRoles(menuItem: any) {
        if (
            this.authService.isUserAuthenticated() &&
            !isNullOrUndefined(menuItem.LinkRegistered) &&
            menuItem.LinkRegistered !== ''
        ) {
            return menuItem.LinkRegisteredRoles;
        }
        return menuItem.LinkRoles;
    }

    getMenuItemColor(color: any) {
        const colorStyle = `color: ${color}`;
        return this.sanitizer.bypassSecurityTrustStyle(colorStyle);
    }

    onSubMenuClick(event: any, subMenu: any) {
        subMenu.classList.toggle('open-sub-menu');
        event.stopPropagation();
    }

    linkClicked(event) {
        event.stopPropagation();
    }

    getRootLink() {
        return this.uiHelper.getRootLink();
    }

    getStyleOfDropdown() {
        if (this.uiHelper.isBrowser && !this.isMobile) {
            if (this.uiHelper.isCookieAppear) {
                return { top: '10rem !important' };
            } else {
                return { top: '5.8rem !important' };
            }
        }
    }

    private initMenu(res) {
        if (this.uiHelper.IsMobile() || this.isMobileLandscape) {
            this.getSubCategory(res.Categories);
        }
        this.menu = res;
        this.cd.detectChanges();
    }

    private insideMobileToggleNav(isNavOpen) {
        if (this.navbarCollapsed !== !isNavOpen) {
            if (this.navbarCollapsed) {
                window.scroll(0, 0);
            }
            this.navbarCollapsed = !isNavOpen;
            document.querySelector('body').classList.toggle('overflow');
            document.querySelector('html').classList.toggle('overflow');
            if (isNavOpen) {
                history.pushState(null, null, location.href);
                this.locationSubscription = this.location.subscribe(() => {
                    this.onBackButtonClick();
                });
            } else {
                this.locationSubscription.unsubscribe();
                $('.navbar-nav .dropdown').each((_, item) => {
                    if ($($(item).children('div')).hasClass('show')) {
                        $(item).click();
                    }
                });
            }
        }
    }

    private insideDesktopToggleNav(isNavOpen) {
        if (this.isNavOpen !== isNavOpen) {
            this.isNavOpen = isNavOpen;
            document.querySelector('.homepage .pop-up-back').classList.toggle('show-back-mega-menu');
        }
    }

    getCompanyLogoBackground() {
        if (this.isEve8) {
            return { 'background-color': '#FFFB94' };
        } else if (this.isEve10) {
            return { 'background-color': '#daf0ff' };
        } else {
            return { 'background-color': 'transparent' };
        }
    }
}
