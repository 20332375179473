<button (click)="close()" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

<div *ngIf="!isLoginFailedWithTechnicalError" class="modal-header">
    <h3>כיף לראות אותך</h3>
</div>

<div *ngIf="!isLoginFailedWithTechnicalError" class="modal-body">
    <div *appLoader="hideLoader; class: 'login'">
        <div *ngIf="!isLoginFailed" class="login-tabs">
            <tabset>
                <tab id="login-password" heading="כניסה עם סיסמה" (selectTab)="passwordTabClicked.next()">
                    <app-user-login-form [dontShowEmptyFieldError]="dontShowEmptyFieldError" (loginFailed)="onLoginFailed($event)" (loginFailedWithTechnicalError)="onLoginFailedWithTechnicalError($event)" (updateLoaderStatusEvent)="updateLoaderStatus($event)" [passwordTabClicked]="passwordTabClicked.asObservable()" [hideForgotPassword]="true" [hideRegisterLink]="true"></app-user-login-form>
                </tab>
            </tabset>
        </div>

        <div *ngIf="isLoginFailed" class="block-login">
            <app-error-screen [params]="errorScreenParams" (onButtonForTryAgainClick)="isLoginFailed=false"></app-error-screen>
        </div>
        <div class="loader-wrapper">
            <div class="loader">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <span class="text">{{loadingText}}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoginFailedWithTechnicalError" class="no-id-now">
    <span class="no-id"></span>
    <div class="text">
        <span class="title">אי אפשר להזדהות כרגע.</span>
        <span>כדאי לנסות להתחבר שוב מאוחר יותר. </span>
    </div>
</div>
