import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { MainLayoutService } from '../../services/main-layout.service';
import { AutoComplete } from 'primeng/autocomplete';
import { a11yFix } from '../../../../shared/utils/a11y.utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-min-search',
    templateUrl: './min-search.component.html',
    styleUrls: ['./min-search.component.scss']
})
export class MinSearchComponent implements OnInit, OnDestroy {
    contentRoot: string;
    @ViewChild('searchBoxTerm', { static: true })
    searchInput: AutoComplete;
    toggleSearchOpen = false;
    isSearchFocused = false;
    textInInput: any;
    results: string[];
    checkboxValue: boolean;
    subs = [];
    isHeaderTransparent: boolean;

    constructor(
        private appConfigsService: AppConfigsService,
        private uiHelper: UiHelperService,
        public layoutService: MainLayoutService,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.subs.push(this.layoutService.headerTransparent.subscribe(val => (this.isHeaderTransparent = val)));
    }

    ngOnInit(): void {
        this.contentRoot = this.appConfigsService.appConfigs.contentRoot;

        this.layoutService.toggleSearchEvent.subscribe(() => {
            this.toggle();
        });

        a11yFix().accordion("input.search-box-toggler", {selectors:{
            headerSelector: "input.search-box-toggler",
            contentSelector: ".search-box-container p-autocomplete"
        }});
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    onInputFocus(): void {
        this.isSearchFocused = true;
    }

    onInputBlur(): void {
        if (!this.textInInput || this.textInInput.trim() === '') {
            this.isSearchFocused = false;
        }
    }

    onSearchClick(event: any): void {
        this.search();
    }

    search(): void {
        if (this.uiHelper.isBrowser) {
            if (this.textInInput && this.textInInput.trim() !== '') {
                setTimeout(() => {
                    if (this.appConfigsService.appConfigs.FLAG_ISUseUmbracoSearch) {
                        this.router.navigate(['/search/main'], {
                            queryParams: {search: this.textInInput}
                        });
                    }
                    else {
                        window.location.href = `${this.contentRoot}/he-il/pages/searchpage.aspx?k=${this.textInInput}`;
                    }
                }, 200);
            }
        }
    }

    onKeyUp(event: any): void {
        if (event.key === 'Enter') {
            this.search();
        }
    }

    onSelect(event: any): void {
        this.search();
    }

    toggleAndChangeCheckboxManually(): void {
        this.checkboxValue = !this.checkboxValue;
        this.toggle();
    }

    toggle(): void {
        if (this.uiHelper.isBrowser) {
            if (this.toggleSearchOpen) {
                this.searchInput.inputEL.nativeElement.blur();
                this.renderer.removeClass(this.searchInput.inputEL.nativeElement, 'placeholder');
            } else {
                if (!this.isHeaderTransparent) {
                    this.searchInput.inputEL.nativeElement.focus();
                }
                this.renderer.addClass(this.searchInput.inputEL.nativeElement, 'placeholder');
            }
            this.toggleSearchOpen = !this.toggleSearchOpen;
            this.layoutService.toggleSearchOpen = this.toggleSearchOpen;
        }
    }

    filterSearch(e) {
        if (e.query.length < 2) {
            this.results = [];
            return;
        }

        this.layoutService.getSearchAutocompleteStrings().subscribe(
            res =>
                (this.results = (res as string[])
                    .filter(item => {
                        return item.indexOf(e.query.toLowerCase()) !== -1;
                    })
                    .slice(0, 5))
        );
    }
}
