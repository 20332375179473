// tslint:disable:max-line-length
import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewEncapsulation,
    Output,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { BehaviorSubject, Subject, SubscriptionLike } from 'rxjs';
import { Location } from '@angular/common';
// tslint:disable-next-line:import-blacklist
import { PrebootService } from 'core-max-lib';
import { IErrorScreenParams } from "../../../login/login-popup/login-messages.consts";
import { ModalService } from "../../../../shared/modules/bs-modal/services/modal.service";
import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { GtmService } from "../../../../shared/cardholders-core/services/gtm.service";

@Component({
    selector: 'app-user-password-login-popup',
    templateUrl: './user-password-login-popup.component.html',
    styleUrls: ['./user-password-login-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserPasswordLoginPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    isLoginFailed = false;
    isLoginFailedWithTechnicalError = false;
    errorScreenParams: IErrorScreenParams;
    hideLoader: BehaviorSubject<boolean>;
    locationSubscription: SubscriptionLike;
    loadingText = '';
    isMobile: boolean;
    dontShowEmptyFieldError: boolean;

    @Output()
    passwordTabClicked: Subject<void> = new Subject<void>();

    constructor(private modalSvc: ModalService,
        private uiHelper: UiHelperService,
        private gtmService: GtmService,
        private location: Location,
        private prebootSvc: PrebootService) {
    }

    async ngOnInit(): Promise<any> {
        this.hideLoader = new BehaviorSubject<boolean>(true);
        this.isMobile = this.uiHelper.IsMobile(768);
        this.loadingText = 'עוד רגע נכנסים...';
        this.updateLoaderStatus(false);

        if (this.uiHelper.isBrowser && this.uiHelper.IsMobile(768)) {
            document.querySelector('body').classList.add('overflow');
            document.querySelector('html').classList.add('overflow');
            history.pushState(null, null, location.href);
            this.locationSubscription = this.location.subscribe(() => {
                this.onBackButtonClick();
            });
        }

        this.prebootSvc.transitionComplete.subscribe((isStable: boolean) => {
            if (isStable) {
                this.updateLoaderStatus(true);
                this.loadingText = 'עוד רגע נכנסים...';
            }
        });

    }

    async ngAfterViewInit(): Promise<any> {
        if (!this.uiHelper.isBrowser) { return; }

            this.gtmService.pushWorkflowEvent({
                category: 'New website - log in',
                action: 'Password login',
                label: 'Show',
                subjectId: '4',
                workflowDescription: 'LoginPassword',
                workflowStep: '1',
                workflowTotalStep: '2'
            });
    }

    ngOnDestroy(): void {
        if (this.uiHelper.isBrowser && this.uiHelper.IsMobile(768)) {
            document.querySelector('body').classList.remove('overflow');
            document.querySelector('html').classList.remove('overflow');
            this.locationSubscription.unsubscribe();
        }
    }

    updateLoaderStatus(loadStatus: boolean): void {
        this.hideLoader.next(loadStatus);
    }


    close(): void {
        if (this.popUpInShowState()) {
            this.modalSvc.closeModal();
        }
    }

    popUpInShowState() {
        return document.querySelector('.modal.fade').classList.contains('show');
    }

    onBackButtonClick() {
        // history.forward();
        this.close();
        return false;
    }

    onLoginFailed(params: IErrorScreenParams): void {
        this.errorScreenParams = params;
        this.isLoginFailed = true;
    }

    onLoginFailedWithTechnicalError(): void {
        this.isLoginFailedWithTechnicalError = true;
        if (this.uiHelper.IsMobile(768)) {
            document.querySelector('.login-popup .modal-content button.close span').classList.add('black-color');
        }
    }

}